/* Variables */
$white: #fff !default;
$black: #000 !default;
$grey: #eee !default;
$dark-grey: #ddd !default;
$orange: #ff8000 !default;
$brown: #ff7300 !default;
/*
$orange: #ff7200 !default;
$brown: #bb5400 !default;
*/
$navbar-height: 48px !default;
$navbar-small-height: 44px !default;
$header-sizes: (
    'h1': 2.4,
    'h2': 2.2,
    'h3': 2,
    'h4': 1.8,
    'h5': 1.5,
    'h6': 1.3,
) !default;
$small-screen-size: 575px;
$medium-screen-size: 768px;
$large-screen-size: 992px;

$post-it: (
    yellow: #ffff88,
    light-yellow: #ffffc6
);

$primary: $orange;
