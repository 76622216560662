/* About Content Styles */
.about-container {
    section {
        margin-top: 2.5em;
    }

    .about-quotes {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        text-align: center;
    }

    .about-image {
        display: flex;
        margin-bottom: 3em;
        align-items: center;
        justify-content: center;

        img {
            display: block;
            width: 100%;
        }

        .equal-sign {
            transform: rotate(90deg);
        }
    }

    .about-logo-image {
        height: 200px; /* Specify the desired height */
        width: auto;   /* Maintain aspect ratio */
        object-fit: cover; /* Ensure the image covers the area */
    }

    .about-embed-image-wrapper {
        position: relative;
        width: 100%;
        padding-top: 56.25%; /* Aspect ratio 16:9 (height/width * 100%) */
    }
    
    .about-embed-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; /* Ensure the image covers the area */
        object-position: center;
    }
}
